@import '../../variables';

$card-padding-top: tovw(16px);
$row-height: auto;

.content-row {
  margin-top: 0;
  padding-bottom: tovw(10px);
  overflow: hidden;

  &__inner {
    padding-top: tovw(12px);
  }

  &__ads {
    margin: 0 tovw(11px);
  }

  &__title {
    position: relative;
    align-items: flex-end;
    color: $tan;
    display: flex;
    font-size: $context-title-font-size;
    font-weight: $font-weight-medium;
    letter-spacing: 0.01em;
    line-height: tovw(32px);
    margin: 0 0 tovw(8px) 0;
    padding-left: $content-left-padding;
    justify-content: space-between;

    .content-title {
      max-width: tovw(775px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__arrow {
    display: flex;
    justify-content: space-between;
    width: tovw(74px);
    margin-right: tovw(58.66px);
  }

  &__carousel {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    height: $row-height;
    min-height: tovw(266px);

    &__spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: tovw(266px);
      height: $row-height;

      // this position is to align spinner of row content with spinner of row
      img {
        position: relative;
        left: tovw(-29px);
      }
    }

    &.not-swiper {
      position: relative;
      overflow-x: visible;
      padding-left: $content-left-padding;
      padding-top: tovw(8px);
      padding-bottom: tovw(8px);

      .content-card-container {
        flex-shrink: 0;
      }
    }

    // This artificial space is to separate the last card from the edge of the viewport.
    // This will fix the problem when calculating the closest item when pressing the down key.
    .content-card:last-of-type::before {
      content: '';
      display: block;
      height: 100%;
      width: tovw(22px);
      top: 0;
      right: tovw(-22px);
      position: absolute;
    }

    &.standard {
      & div:first-child a {
        margin-left: 0;
        margin-top: 0;
      }
    }

    &.details {
      height: tovw(295px);

      & div:first-child a {
        margin-left: 0;
        margin-top: 0;
      }
    }

    &.originals {
      height: tovw(370px);

      & div:first-child a {
        margin-top: 0;
        margin-left: 0;
      }

      & .content-card__overlay div {
        margin-top: 0;
        margin-left: 0;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .left-arrow,
  .right-arrow {
    width: tovw(32px);
    height: tovw(32px);
    background-color: transparent;
    border: tovw(2px) solid $white;
    box-sizing: border-box;
    border-radius: tovw(5.33px);
    z-index: 50;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: $white;
      width: tovw(5.95px);
      height: tovw(10.66px);
    }

    &.disabled {
      border-color: $button-disabled;
      pointer-events: none;

      svg {
        fill: $button-disabled;
      }
    }

    &:hover {
      background-color: $orange;
      border-color: $orange;

      svg {
        fill: $charcoal;
      }
    }
  }

  .left-arrow {
    svg {
      transform: rotate(180deg);
    }
  }

  .content-ads-title {
    display: flex;
    align-items: center;
  }
}

// the padding is already in lazy-loader
.lazy-loader .content-row__title {
  height: tovw(30px);
  max-width: tovw(775px);
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.lazy-loader .content-row__title,
.lazy-loader .content-row__carousel {
  padding-left: 0;
}

.see-all {
  justify-content: center;
  align-items: center;

  .see-all__fallback-text {
    text-align: center;
    color: $white;
    cursor: pointer;
    font-weight: $font-weight-bold;
    font-size: tovw(24px);
    line-height: tovw(30px);
    margin-right: 0;
    white-space: nowrap;
    appearance: none;
    background-color: transparent;
    border: none;
    z-index: 3;
  }
}
