@import '../../variables';

.app-submenu {
  background: transparent !important;
  justify-content: center;
  opacity: 0;
  position: absolute;
  width: tovw(1px);
  z-index: 11;
  pointer-events: auto;

  &__spinner {
    position: fixed;
    top: 70%;
    left: 10%;
  }

  // Internet Explorer, Edge
  -ms-overflow-style: none;

  &.genre {
    display: block;
  }

  &.open {
    opacity: 1;
  }

  &__item {
    border-radius: $border-radius-normal;
    padding-left: tovw(16px);

    &:nth-child(1) {
      margin-top: tovw(298px);
    }

    &:last-child {
      margin-bottom: tovw(58px);
    }

    span {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
}

.content-subgenre-menu {
  background: transparent !important;
  justify-content: center;
  left: tovw(58.67px);
  position: absolute;
  width: $navbar-width-open;
  height: $fullscreen-height;
  z-index: 10;
  padding: tovw(15px) 0;

  .app-submenu {
    position: relative;
  }
}
