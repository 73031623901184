@import '../../variables';

$content-margin: tovw(58px);

.auth-overlay {
  align-items: center;
  background: url('../../assets/images/welcome_start.png') no-repeat fixed
    center $charcoal;
  background-color: $charcoal;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  &__phone {
    position: absolute;
    top: $content-margin;
    height: tovw(32px);
    width: tovw(22px);
    left: 50%;
    transform: translateX(-50%);

    path {
      fill: $tan;
    }
  }

  &__top {
    margin: tovw(148px) 0 tovw(64px) 0;
    text-align: center;

    div {
      margin-bottom: tovw(16px);
    }

    .url,
    .code {
      font-weight: $font-weight-light;
      font-size: $grid-title-font-size;
      line-height: tovw(53px);
    }

    .url {
      color: $orange;
      letter-spacing: -0.03em;
    }

    .description {
      color: $tan;
      font-size: $regular-font-size;
      font-weight: $font-weight-medium;
      line-height: tovw(20px);
    }

    .code {
      text-transform: uppercase;
      color: $blue;
      letter-spacing: 0.75em;
      text-indent: 0.75em;
    }
  }

  &__bottom {
    &__description {
      color: $tan;
      font-size: $regular-font-size;
      font-weight: $font-weight-medium;
      line-height: tovw(20px);
    }

    button {
      margin-bottom: tovw(16px);
    }

    position: absolute;
    bottom: $content-margin;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__spinner {
    margin-top: tovw(48px);
  }

  &__welcome {
    align-items: center;
    background: url('../../assets/images/welcome_start.png') no-repeat fixed
      center $charcoal;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    width: 100%;

    &__text-container {
      text-align: center;
      z-index: 2;

      div {
        margin-bottom: tovw(16px);
      }

      .title {
        font-weight: $font-weight-light;
        font-size: $grid-title-font-size;
        line-height: tovw(53px);
        color: $orange;
        letter-spacing: -0.03em;
      }

      .description {
        color: $tan;
        font-size: $regular-font-size;
        font-weight: $font-weight-medium;
        line-height: tovw(20px);
      }
    }

    &__spinner {
      margin-top: tovw(48px);
    }
  }

  &__timeout {
    align-items: center;
    background: url('../../assets/images/welcome_start.png') no-repeat fixed
      center $charcoal;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    &__text-container {
      text-align: center;

      div {
        margin-bottom: tovw(16px);
      }

      .title {
        font-weight: $font-weight-light;
        font-size: $grid-title-font-size;
        line-height: tovw(53px);
        color: $orange;
        letter-spacing: -0.03em;
      }

      .description {
        color: $tan;
        font-size: $regular-font-size;
        font-weight: $font-weight-medium;
        line-height: tovw(20px);
      }
    }

    &__buttons {
      position: absolute;
      bottom: tovw(58px);
      left: 50%;
      transform: translateX(-50%);

      > div.inner {
        display: flex;
        flex-direction: row;
      }

      button:first-child {
        margin-right: tovw(16px);
      }

      button:last-child {
        svg {
          color: $white;
        }

        &:focus {
          svg {
            color: $charcoal;
          }
        }
      }
    }
  }
}
