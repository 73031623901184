@import '../../variables';

.continue-watching-row {
  padding-left: tovw(58px);

  &__content-row__title {
    font-size: $context-title-font-size;
    font-weight: $font-weight-medium;
    letter-spacing: 0.01em;
    line-height: tovw(30px);
    max-width: tovw(745px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.originals-row {
  height: tovw(448px);
}

.standard-row {
  height: tovw(334px);
}

.details-row {
  height: tovw(373px);
}
