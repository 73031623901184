@import '../../variables';

.rewards-overlay {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(#{tovw(4px)});

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;

    .invisible-button {
      background-color: none;
      border: none;

      .hover-disabled &:focus,
      .hover-enabled &:hover {
        background: none;
        border: none;
      }
    }

    &__icon {
      width: tovw(32px);
      height: tovw(32px);
      position: absolute;
      top: tovw(62px);
      left: 50%;
      transform: translateX(-50%);

      svg {
        width: tovw(32px);
        height: tovw(32px);
      }
    }

    &__title {
      height: tovw(130px);
      width: tovw(370px);
      text-align: left;
      padding-top: tovw(52px);

      h2 {
        font-size: tovw(48px);
        color: $tan;
        font-weight: $font-weight-bold;
        margin-top: 0;
        margin-bottom: tovw(22px);
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 1;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    &__image {
      background-color: $charcoal;
      height: tovw(208.13px);
      width: tovw(370px);
      position: relative;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__description {
      width: tovw(370px);
      background: none;
      font-weight: $font-weight-medium;
      font-size: tovw(16px);
      line-height: tovw(26px);
      text-align: left;
      color: $white;
      margin-top: tovw(10px);
      margin-bottom: tovw(8px);

      p {
        margin-top: 0;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 3;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &__buttons {
      margin-top: 0;
      padding-top: 0;
      display: flex;
      flex-direction: row;

      a,
      button {
        margin-right: tovw(16px);
        margin-top: tovw(8px);
        margin-bottom: tovw(32px);
      }
    }
  }

  &__tos {
    position: absolute;
    line-height: tovw(16.8px);
    bottom: tovw(20px);
    font-size: tovw(13.3339px);
    font-weight: $font-weight-light;
    text-align: center;
    word-wrap: break-word;
    color: $tan;
    width: tovw(850px);
    align-items: flex-end;
  }
}
