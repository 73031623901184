@import '../../variables';

$button-space: tovw(8px);

%elements {
  align-self: center;
  background: none;
  border-radius: $border-radius-normal;
  border: none;
  color: inherit;
  height: tovw(52px);
  margin: 0;
  outline: none;
  padding: $button-space;
  width: tovw(36px);

  .hover-disabled &:focus,
  .hover-enabled &:hover {
    background: $orange;
    color: $charcoal;
  }

  &:disabled {
    color: gray;
  }
}

.number-strip {
  color: $white;
  display: flex;
  flex-direction: row;
  height: fit-content;

  button {
    @extend %elements;

    font-size: $regular-font-size;
    font-weight: $font-weight-bold;

    &.bordered-button {
      border: tovw(1px) solid;
      border-radius: $border-radius-small;
      font-size: tovw(12px);
      height: tovw(26px);
      margin-right: tovw(10px);
      width: tovw(73.33px);
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0;
    }

    &.abc-btn {
      width: tovw(40px);
    }

    svg {
      width: 100%;
      height: tovw(20px);
    }
  }

  &__backspace-icon {
    @extend %elements;

    padding: tovw(18px) $button-space;
  }
}
