@import '../../variables';

.get-your-watchlist {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: $fullscreen-height;
  margin: 0;
  padding: 0;
  width: 100%;
  background: url($background-blur) no-repeat fixed center $charcoal;

  .add-icon {
    margin-top: tovw(45px);
    height: tovw(32px);
    width: tovw(36px);
    color: $tan;
  }

  &__content {
    display: flex;
    position: absolute;
    align-items: center;
    width: tovw(600px);
    height: tovw(600px);
    left: 50%;
    transform: translateX(-50%);

    &__left {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      margin-right: tovw(16px);
      text-align: right;
      padding-top: tovw(100px);
      width: tovw(300px);

      h1 {
        color: $primary-font-light;
        font-size: $grid-title-font-size;
        line-height: $grid-title-font-size;
        font-weight: $font-weight-light;
        margin: 0;
      }

      .watchlist-title {
        color: $orange;
      }
    }

    &__right {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      align-self: center;
      width: tovw(300px);
      height: tovw(90px);
      text-decoration: none;

      &__sign-up-button {
        text-decoration: none;
        margin-bottom: tovw(24px);
      }

      &__sign-in-button {
        text-decoration: none;
        margin-bottom: tovw(24px);
        max-width: tovw(91px);
      }

      button {
        margin-bottom: tovw(24px);
        white-space: nowrap;
        height: tovw(50px);
        width: fit-content;

        svg {
          height: tovw(16px);
          width: tovw(16px);
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__footer {
    bottom: tovw(50px);
    color: $tan;
    font-size: tovw(16px);
    font-weight: $font-weight-medium;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
  }
}
