@use 'sass:math';

@function tovw($target) {
  $vw-context: (1280 * 0.01) * 1px;

  @return math.div($target, $vw-context) * 1vw;
}

// Fullscreen height
$fullscreen-height: 56.25vw;

// Animations
$delayed-animation: 0.2s ease-in-out;

// Colors
$orange: #ff601c;
$charcoal: #050505;
$blue: #009ffd;
$white: #eaf6ff;
$white-15: rgba($white, 0.15);
$white-25: rgba($white, 0.25);
$white-50: rgba($white, 0.5);
$tan: #f1dac4;
$yellow: #ffba08;
$red: #f33;
$black-0: rgba($charcoal, 0);
$black-25: rgba($charcoal, 0.25);
$black-40: rgba($charcoal, 0.4);
$black-60: rgba($charcoal, 0.6);
$black-80: rgba($charcoal, 0.8);
$black-90: rgba($charcoal, 0.9);
$black-96: rgba($charcoal, 0.96);
$border-black: rgb(234 246 255 / 15%);
$primary-light: $orange;
$primary-dark: $charcoal;
$primary-font-light: $tan;
$primary-font-dark: $orange;
$secondary-font-dark: $charcoal;
$gradient-bottom-left: radial-gradient(
  145.25% 77.75% at 0% 100%,
  #ff601c 0%,
  rgb(255 96 28 / 0%) 100%
);
$gradient-top-right: radial-gradient(
  50% 200% at 100% 0%,
  rgb(0 159 253 / 25%) 0%,
  rgb(0 159 253 / 0%) 100%
);
$border-black-medium: rgb(234 246 255 / 25%);
$lighter-black: #141414;
$exit-background: $charcoal;
$button-disabled: #808080;
$badge-outline: $white-15;
$badge-background: rgba($charcoal, 0.8);
$ad-marker: $yellow;
$metadata-color: $tan;

// Dimensions
$border-radius-medium: tovw(4px);
$border-radius-normal: tovw(6px);
$border-radius-small: tovw(3px);
$border-radius-large: tovw(12px);
$border-radius-content-card: tovw(6px);
$card-column-margin: tovw(8px);
$column-gap: tovw(16px);
$content-left-padding: tovw(58px);
$content-bottom-padding: tovw(58px);
$navbar-width-closed: tovw(58.66px);
$navbar-width-open: tovw(245.33px);
$standard-card-height: tovw(240px);
$standard-card-width: tovw(160px);
$block-overflow: tovw(20px);
$grid-width: calc(100% - #{$navbar-width-open});
$navbar-logo-width: tovw(151px);
$navbar-logo-height: tovw(27px);
$navbar-logo-padding: tovw(21px) 0 0 tovw(21px);
$content-card-focused-border: inset 0 0 0 tovw(2px) $orange;
$small-font-size: tovw(12px);
$regular-font-size: tovw(16px);
$context-title-font-size: tovw(24px);
$medium-title-font-size: tovw(32px);
$main-title-font-size: tovw(48px);
$grid-title-font-size: tovw(64px);
$scrubbing-icons-width: tovw(21.84px);
$scrubbing-icons-height: tovw(20.2px);
$double-arrow-icon-width: tovw(57px);
$double-arrow-icon-height: tovw(45px);
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-medium: 400;
$font-weight-medium-bold: 500;
$font-weight-bold: 700;
$font-weight-bold-title: 700;

// Icons
$regular-icon-size: tovw(16px);

// Images
$background-blur: '/assets/images/background-blur.jpg';

@mixin set-black($property, $alpha) {
  #{$property}: rgba($charcoal, $alpha);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin set-modal-icon($icon-width, $icon-height) {
  position: absolute;
  top: tovw(62px);
  left: calc(50% + (#{$icon-width} / 2));
  width: $icon-width;
  height: $icon-height;
}

// Re-usable Classes
%centered-flex {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
}

%modal-container {
  @extend %centered-flex;

  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

%modal-content-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-right: tovw(8px);

  > h1 {
    font-size: $grid-title-font-size;
    font-weight: $font-weight-light;
    line-height: $grid-title-font-size;
  }
}

%modal-content-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: tovw(8px);

  a,
  button {
    text-decoration: none;
    margin-bottom: tovw(16px);
  }
}

%modal-footer {
  font-size: $regular-font-size;
  font-weight: $font-weight-medium;
  position: absolute;
  bottom: tovw(59px);
}

%crackle-logo-container {
  @extend %centered-flex;

  border-radius: $border-radius-normal;
  padding: tovw(10.7px);
  position: absolute;
  color: $orange;

  /* height: tovw(38px);
  width: tovw(100px); */
}

@import 'brand-variables';
