@import '../../variables';

// Local Variables
$overlay-margin: $border-radius-large;

.see-all-card {
  background-position: center;
  background-size: cover;
  border-radius: $border-radius-content-card;
  display: flex;
  flex-direction: column;
  margin: 0 $card-column-margin;
  max-height: $standard-card-height;
  min-height: $standard-card-height;
  min-width: $standard-card-width;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    border-radius: $border-radius-content-card;
    box-shadow: inset 0 0 0 tovw(1px) $border-black;
    transition: none;
  }

  .hover-disabled div:not(.swiper-slide) > &:focus,
  .hover-enabled &:hover {
    .see-all-card {
      transform: scale(1.06);
    }

    .see-all-card__poster-container {
      transform: scale(1.06);
    }

    &::after {
      box-shadow: $content-card-focused-border;
      transform: scale(1.06);
    }
  }

  &__poster-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    background-color: $charcoal;
    border-radius: $border-radius-content-card;

    img {
      border-radius: $border-radius-content-card;
      max-height: $standard-card-height;
      min-height: $standard-card-height;
      object-fit: cover;
      transition: none;
      width: 100%;
    }
  }

  &__fallback-text {
    color: $white;
    opacity: 0.5;
    position: inherit;
    width: 100%;
    text-align: center;
    word-break: break-word;
    overflow-wrap: break-word;
    text-transform: uppercase;

    &.standard {
      font-size: $context-title-font-size;
      font-weight: $font-weight-bold;
      font-style: normal;
      line-height: tovw(29.4px);
      margin: 0 0 0 tovw(12px);
      width: calc(100% - #{tovw(24px)});
    }
  }

  &.standard {
    justify-content: center;
    width: $standard-card-width;
    text-decoration: none;
    margin-bottom: tovw(32px);
  }

  .hover-enabled &:hover {
    .see-all-card {
      opacity: 1;
      visibility: visible;
    }
  }
}
