@import '../../variables';

.button {
  @extend %centered-flex;

  background: transparent;
  border: tovw(2px) solid $white;
  border-radius: $border-radius-normal;
  color: $white;
  font-size: $regular-font-size;
  font-weight: $font-weight-bold;
  height: tovw(52px);
  justify-content: space-evenly;
  line-height: $regular-font-size;
  padding: tovw(16px) tovw(15px);
  width: fit-content;

  svg {
    height: $regular-font-size;
    width: $regular-font-size;
    margin-right: tovw(10px);
    color: $white;
  }

  &.join-rewards {
    .hover-disabled &:focus,
    .hover-enabled &:hover {
      background: $blue;
      border: tovw(1.34px) $blue;
      color: $primary-dark;
    }
  }

  &.watch-now {
    min-width: tovw(168.7px);
  }

  .hover-disabled &:focus,
  .hover-enabled &:hover {
    background: $orange;
    border: tovw(2px) solid $orange;
    color: $primary-dark;

    svg {
      fill: $primary-dark;

      path {
        fill: $primary-dark;
      }
    }

    & .progress-bar {
      background-color: $black-25;

      &.button {
        div {
          background-color: $charcoal;
        }
      }
    }
  }

  &.icon-button {
    min-width: tovw(100px);
    text-decoration: none;
  }

  &.progress-button {
    flex-wrap: wrap;
    min-width: tovw(130px);
    text-decoration: none;

    svg {
      margin-right: tovw(10px);
    }
  }

  &__progress {
    border: none;
    color: $black-60;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  &.disabled {
    opacity: 0.25;
  }
}
